<template>
  <section class="d-flex flex-column">
    <hs-group
      :label="$t('components.automation-input.components.webhook-input.url.label')"
      label-for="url"
      label-class="font-weight-bold"
      class="mb-3"
    >
      <MInput
        id="url"
        :placeholder="$t('components.automation-input.components.webhook-input.url.placeholder')"
        v-model="form.url"
        :state="!$v.form.url.$error ? null : false"
        @blur="$v.form.url.$touch()"
      >
        <template slot="feedback" v-if="$v.form.url.$error">
          <hs-form-invalid-feedback :state="false" v-if="!$v.form.url.required || !$v.form.url.url">
            {{ $t('validations.valid') }}
          </hs-form-invalid-feedback>
        </template>
      </MInput>
    </hs-group>

    <hs-group
      :label="$t('components.automation-input.components.webhook-input.method.label')"
      label-for="request-method"
      label-class="font-weight-bold"
      class="mb-3"
    >
      <MXMultiSelect
        @change="onChangeRequestMethod"
        v-model="form.request_method"
        :options="selectOptions"
        :searchable="false"
        :showLabels="false"
      />
    </hs-group>

    <MXInputCheckbox
      v-model="isToUseHeaders"
      class="mb-3"
      :label="$t('components.automation-input.components.webhook-input.headers.checkbox-text')"
    />

    <div class="border mb-3 headers-container" v-if="isToUseHeaders">
      <div class="p-3 headers-title mb-3">
        {{ $t('components.automation-input.components.webhook-input.headers.title') }}
      </div>

      <div class="px-3" v-for="(header, index) in form.headers" :key="header.id">
        <div class="d-flex align-items-center mt-1 mb-2">
          <div class="w-50">
            <MInput
              v-model="header.key"
              :placeholder="$t('components.automation-input.components.webhook-input.headers.key')"
            />
          </div>
          <div class="w-50 ml-2">
            <MInput
              v-model="header.value"
              :placeholder="$t('components.automation-input.components.webhook-input.headers.value')"
            />
          </div>

          <hs-button
            :disabled="index === 0"
            class="text-danger"
            icon="trash-alt"
            variant="solid"
            @click="onRemoveHeader(header)"
          />
        </div>
      </div>

      <MXButton class="mb-3 ml-2" size="sm" icon="far fa-plus" variant="tertiary" @click="onAddHeader">
        {{ $t('components.automation-input.components.webhook-input.headers.new') }}
      </MXButton>
    </div>

    <div>
      <label class="font-weight-bold">{{
        $t('components.automation-input.components.webhook-input.body.label')
      }}</label>
      <codemirror class="mb-3" v-model="form.body" :options="cmOptions" />
    </div>

    <div>
      <!-- <MXButton variant="secondary" @click="onTestWebhook" :disabled="$v.$invalid" icon="far fa-paper-plane">
        {{ $t('components.automation-input.components.webhook-input.webhook.test-text') }}
      </MXButton> -->

      <hs-loading v-if="isLoadingWebhook" />

      <hr />

      <div class="my-4 border" v-if="!isLoadingWebhook && responseWebhook">
        <h5 class="py-3 pl-3 mb-2 text-white font-weight-bold text-dark border-bottom">
          {{ $t('components.automation-input.components.webhook-input.webhook.response-title') }}
          {{ form.url }}
        </h5>
        <div class="pl-3 mt-3">
          <span class="font-weight-bold">{{
            $t('components.automation-input.components.webhook-input.webhook.response-subtitle')
          }}</span>
          <pre class="mt-3">{{ responseWebhook }}</pre>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <MXButton variant="secondary" @click="$emit('cancel')">
        {{ $t('actions.cancel-and-back') }}
      </MXButton>

      <MXButton class="ml-3" variant="primary" @click="onSave" :disabled="$v.$invalid">
        {{ $t('actions.save') }}
      </MXButton>
    </div>
  </section>
</template>

<script>
import { hsLoading } from '@/components';
import { required, url } from 'vuelidate/lib/validators';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/theme/dracula.css';
import 'codemirror/addon/selection/active-line.js';
import MXButton from '@/shared/components/MXButton.vue';
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';
import MInput from '@/components/MInput.vue';

export default {
  name: 'WebhookInput',
  components: {
    hsLoading,
    codemirror,
    MXButton,
    MXInputCheckbox,
    MInput,
  },

  data() {
    return {
      cmOptions: {
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        theme: 'dracula',
      },
      responseWebhook: null,
      isLoadingWebhook: false,
      isToUseHeaders: false,
      form: {},
    };
  },

  props: {
    automation: {
      type: Object,
      default() {
        return {
          url: null,
          request_method: 'POST',
          request_headers: {},
          body: "{'key1' => 'value1','key2' => 'value2'}",
          headers: [],
        };
      },
    },
  },
  validations: {
    form: {
      url: {
        required,
        url,
      },
    },
  },
  computed: {
    formToSave() {
      return {
        scope: 'Integration',
        integration: 'HeroWebhook',
        settings: {
          scope: 'Integration',
          ...this.form,
          request_headers: this.form.headers.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
        },
      };
    },
    selectOptions() {
      return ['POST', 'PUT', 'PATCH', 'DELETE', 'MOVE', 'COPY', 'HEAD'];
    },
  },
  methods: {
    onSave() {
      this.$emit('save', this.formToSave);
    },
    onAddHeader() {
      this.form.headers.push({ id: Math.random(), key: '', value: '' });
    },
    onRemoveHeader(header) {
      this.form.headers = this.form.headers.filter(h => h.id !== header.id);
    },
    onChangeRequestMethod() {
      this.responseWebhook = null;
    },
    onTestWebhook() {
      this.isLoadingWebhook = true;

      const { settings } = this.formToSave;

      fetch(settings.url, {
        method: settings.request_method,
        headers: settings.request_headers,
        ...(settings.body && {
          body: settings.body,
        }),
      })
        .then(response => response.json())
        .then(responseWebhook => (this.responseWebhook = responseWebhook))
        .catch(err => (this.responseWebhook = err))
        .finally(() => (this.isLoadingWebhook = false));
    },
  },
  watch: {
    isToUseHeaders(value) {
      if (!value) this.form.headers = [];
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.automation));

    const headers = Object.entries(this.form.request_headers).map(value => ({
      id: Math.random(),
      key: value[0],
      value: value[1],
    }));

    this.$set(this.form, 'headers', headers);
    this.isToUseHeaders = Boolean(headers.length);
  },
};
</script>

<style lang="scss" scoped>
.headers-container {
  border-radius: 0.675rem;
}

.headers-title {
  color: $grey;
  background-color: #f3ebfa;
}

/deep/.multiselect__option:hover {
  background-color: #ead3fe;
  border: transparent 1px solid !important;
  color: #262626 !important;
}

/deep/.multiselect__option--selected {
  background-color: #7427f1 !important;
  border: #7427f1 1px solid !important;
  color: #fff !important;
}

/deep/.multiselect__option--highlight {
  background-color: #ead3fe !important;
  border: #7427f1 1px solid !important;
  color: #262626 !important;
}

/deep/.multiselect__element > span {
  border: transparent solid 1px !important;
}
</style>
